import * as React from "react";
import { Link } from "gatsby";
import { css, useTheme } from "@fckdtl/themotion/react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PageHeader from "../components/PageHeader";
import { P, Overline, H1, H3, H4, Subtitle1 } from "../components/texts";
import Button from "../components/Button";
import { StaticImage } from "gatsby-plugin-image";
import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import ContentCell from "../components/layout/ContentCell";

const IndexPage = () => {
  const theme = useTheme();
  return (
    <Layout>
      <Seo title="Home" />
      <PageHeader
        overline="Ben Donath"
        headline="Essen ist lebensnotwendig, also mach es gut."
        subline={[
          "Food Photography",
          "Food Styling",
          "Rezept-Entwicklung",
          "Rezept Videos",
          "Kooperationen",
          "Show-Kochen",
          "Kurse",
        ].map((item, index, { length }) => (
          <>
            {item.replace(" ", "\u00A0") +
              (length - 1 - index > 0 ? "\u00A0| " : "")}
          </>
        ))}
      />
      <StaticImage
        src="../images/content/hero-benjamin-donath.png"
        placeholder="dominantColor"
        layout="fullWidth"
        alt=""
      />
      <Grid>
        <Row>
          <ContentCell>
            <H3 as="h2">
              Eatery Berlin is a place where good food meets a good cook
            </H3>
            <P>
              After twenty years in the culinary industry the essence is simple:
              I chose these words, to be the credo for Eatery Berlin. A simple
              rice pudding deserves the same amount of dedication, as it does to
              cook a fancy menu. What you will find on this site can be
              everything, but most of all it will be quality, so you will enjoy
              it as much as I do. Keep on stirring in your pots and pans!
            </P>
            <H4 as="h2">Wir unterstützen dein Projekt</H4>
            <div
              css={css(`
            margin: -8px -5px;
            > * {
            margin: 8px 5px;
          }`)()}
            >
              {[
                "Food Photography",
                "Food Styling",
                "Rezept-Entwicklung",
                "Rezept Videos",
                "Kooperationen",
                "Show-Kochen",
                "Kurse",
              ].map((item, ix) => (
                <Button key={item + ix} as="span" small>
                  {item}
                </Button>
              ))}
            </div>
          </ContentCell>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
            <H1 as="h2">Rezepte</H1>
          </Cell>
        </Row>
        <Row>
          <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
            <H1 as="h2">Stories</H1>
          </Cell>
        </Row>
        <Row>
          <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
            <H1 as="h2">Videos</H1>
          </Cell>
        </Row>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
